import React from "react";
import Header from "./Header";
import HomeFooter from "./HomeFooter";
import ScrollUp from "./ScrollUp";

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div className="grow">{children}</div>
      <ScrollUp/>
      <HomeFooter />
    </>
  );
};

export default Layout;
