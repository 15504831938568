import React from 'react'
import { Navigate, useLocation } from 'react-router-dom';

function ShortStory() {
    const location =useLocation();
    if (!location.state || !location.state.id) {
        return <Navigate to="*"/>;
      }
  return (
    <div>ShortStory</div>
  )
}

export default ShortStory