import React from "react";
import HomeAuthors from "../components/HomeAuthors";
import PoemsHomeSlider from "../components/PoemsHomeSlider";
import { items } from "../utils";

function HomePage() {
  return (
    <div>
      <div className="py-4 sm:py-7">
        <h4 className="text-2xl sm:text-6xl text-center font-black uppercase">
          Writers Hand
        </h4>
        <p className="w-2/3  sm:w-1.5/2 mx-auto text-center text-lg  sm:text-3xl">
          {" "}
          A platform for writers and readers that cover a wide range of poems
          and stories written by skilled hands across Africa.
          <br />
          Are you a writer?
          <br />
          <div className="flex gap-6 sm:gap-8 items-center mx-auto text-lg sm:text-2xl justify-center mt-4 select-none uppercase">
            <a
              href="/register"
              className="che-yellow text-white py-2 px-3 rounded-2xl font-semibold hover:bg-gray-400 active:ring "
            >
              Register
            </a>
            <a
              href="/login"
              className="che-yellow text-white py-2 px-3 rounded-2xl font-semibold hover:bg-gray-400 active:ring"
            >
              Login
            </a>
          </div>
        </p>
      </div>
      <div className="che-yellow py-4">
        <PoemsHomeSlider items={items} />
      </div>
      <div classBane="py-3 sm:py-6 bg-white ">
        <HomeAuthors />
      </div>
    </div>
  );
}

export default HomePage;
