import React from "react";
import BreadCrumb from "../components/BreadCrumb";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCardImage,
} from "mdb-react-ui-kit";
import { cartoons } from "../utils";

function Cartoons() {
  return (
    <div>
      <div className="container-md mt-3 sm:mt-5">
        <BreadCrumb location="Cartoon" />
        <div>
          <MDBBreadcrumb className="font-black text-sm sm:py-6 sm:text-2xl">
            <span>Subjects: </span>
            <MDBBreadcrumbItem active>
              <span className="che-yellow-text ">All</span>
            </MDBBreadcrumbItem>
            <MDBBreadcrumbItem>
              <span className="che-yellow-text ">Politics</span>
            </MDBBreadcrumbItem>
            <MDBBreadcrumbItem>
              <span className="che-yellow-text ">Love</span>
            </MDBBreadcrumbItem>
            <MDBBreadcrumbItem>
              <span className="che-yellow-text ">Education</span>
            </MDBBreadcrumbItem>
          </MDBBreadcrumb>
          <span className="uppercase  mt-2 sm:text-xl">
            {" "}
            Showing 1 to 3 of 50 Cartoons
          </span>
          <div className="mt-3 mb-4 grid grid-cols-1 space-y-4">
            {cartoons.map((cartoon, index) => {
              return (
                <div key={index}>
                  <h3 className="font-black text-2xl sm:text-3xl">
                    {cartoon.banner}
                  </h3>
                  <p className="uppercase text-lg sm:text-2xl">
                    By {cartoon.author}
                  </p>
                  <MDBCardImage
                    src={`${cartoon.image}`}
                    className=" border-2 border-dark max-w-none img-fluid"
                    alt=""
                    fluid
                  />
                  <a className="mt-1 font-normal text:text-lg sm:text-xl underline decoration-slice text-dark">
                    show more
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cartoons;
