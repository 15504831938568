import { MDBIcon } from "mdb-react-ui-kit";
import React, { useState } from "react";
import { FcUp } from "react-icons/fc";

function ScrollUp() {
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <div>
      <MDBIcon fas icon="arrow-circle-up"
        onClick={scrollToTop}
        className="text-2xl md:text-2xl  shadow-md focus:shadow-lg focus:outline-none  focus:bg-gray-300  active:text-green-600 active:shadow-lg transition duration-150 ease-in-out hidden bottom-5 right-5 fixed text-gray-600"
        style={{ display: visible ? "inline" : "none" }}
      />
    </div>
  );
}

export default ScrollUp;
