import React from 'react'
import { MDBBreadcrumb, MDBBreadcrumbItem } from "mdb-react-ui-kit";

function BreadCrumb({location}) {
  return (
    <div>
        <MDBBreadcrumb className="font-bold text-xl py-4 sm:py-6 sm:text-3xl">
          <MDBBreadcrumbItem>
            <a className="che-yellow-text ml-4 opacity-80" href="/">
              Front Cover
            </a>
          </MDBBreadcrumbItem>
          <MDBBreadcrumbItem active>
            {" "}
            <span className="che-yellow-text ">{location}</span>
          </MDBBreadcrumbItem>
        </MDBBreadcrumb>
    </div>
  )
}

export default BreadCrumb