import React from "react";
import { homeAuthors } from "../utils";

function HomeAuthors() {
  return (
    <div className="py-2 ">
      <h3 className="text-center che-yellow-text font-bold text-2xl sm:text-4xl mb-3 mt-4">
        Trending Writers
      </h3>
      <div className="container grid grid-cols-1 md:grid-cols-3 justify-between gap-3 py-4">
        {homeAuthors.map((val, key) => {
          return (
            <div key={key} className="shadow mb-2">
              <img
                src={`${val.image}`}
                className="object-contain w-full h-48"
                alt=""
                fluid
              />
              <h4 className="text-lg sm:text-2xl font-black text-center che-yellow-text uppercase ">
                {val.name}
              </h4>
              <p className="text-lg sm:text-xl  text-center  capitalize ">
                {val.types.userType}
              </p>
              <p className="text-lg sm:text-xl font-semibold text-center  uppercase ">
                {val.types.title}
              </p>
              <p className="text-lg sm:text-xl  mb-2 text-center  uppercase ">
                {val.types.description},{val.types.total}{" "}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default HomeAuthors;
