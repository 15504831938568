import { MDBCardImage, MDBIcon } from "mdb-react-ui-kit";
import React, { useState } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { cartoons } from "../utils";
const PageSize =3;

function CartoonAuthor() {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(cartoons.length / PageSize);
  const startIndex = (currentPage - 1) * PageSize;
  const endIndex = startIndex + PageSize;
  const myItems = cartoons.slice(startIndex, endIndex);

  
    if (!location.state || !location.state.id) {
      return <Navigate to="*"/>;
    }

  return (
    <div>
      <div className="py-3 container">
        <p onClick={()=>navigate("/cartoons/authors", {state:null})} className="font-black text-base sm:text-lg text-start opacity-70 active:hover:text-blue-600">
          <MDBIcon fas icon="angle-left" /> Back to Previous
        </p>
        <div className="container-md mt-3 pl-4">
          <h5 className=" pl-4 che-black-text font-bold sm:text-2xl">
            {location.state.name}
          </h5>
          <MDBCardImage
            src={`${location.state.img}`}
            className=" ml-4  mr-6 sm:mr-4 ring ring-gray-900 sm:w-4/5 sm:h-36"
            alt=""
            fluid
          />
          <p className="pl-4 pr-4 mt-2">{location.state.body}</p>
        </div>
      </div>
      <div className="container mb-4 ml-2">
        <h5 className="che-black-text font-black text-start">Cartoons by {location.state.name}</h5>
        <div>
          <span className="ml-2 text-white font-black capitalize che-yellow py-2 mt-1 mb-3 border-b-4 border-gray-600 rounded-md px-3">{location.state.banner}</span>
        <div className="ml-3 mt-4 mb-4 grid  grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-x-3 gap-y-4">
          {myItems.map((item, index) => {
            if(item.authorId==location.state.id){
              return (
                <div key={index} className=" img-thumbnail">
                  <MDBCardImage
                    src={`${item.image}`}
                    className="mx-auto mt-2 max-h-48"
                    fluid
                    alt=""
                  />                  
                </div>
              );
            }
          
          })}
        </div>
        <span className="m-4 mt-7 gap-6 justify-evenly">
            <button
              className="active:hover:text-gray-900 border py-2 px-2.5"
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <MDBIcon fas icon="caret-left" />
            </button>
            <button
              className="ml-3 active:hover:text-gray-900 border py-2 px-2.5"
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <MDBIcon fas icon="caret-right" />
            </button>
          </span>
        </div>
      </div>
    </div>
  );
}

export default CartoonAuthor;
