import React, { useState } from "react";
import BreadCrumb from "../components/BreadCrumb";
import ReactPaginate from "react-paginate";
import { shortstories } from "../utils";
import { MDBCardImage } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";

function Stories() {
  const [pageNumber, setPageNumber] = useState(0);
  const navigate = useNavigate();
  // ?per pages
  const numberOfPages = 6;
  const pagesVisited = pageNumber * numberOfPages;

  const displayShorties = shortstories
    .slice(pagesVisited, pagesVisited + numberOfPages)
    .map((story, index) => {
      return (
        <div key={index} className="mb-4 border card p-2 select-none">
          <p className="che-black-text font-bold text-center mt-2 capitalize text-lg sm:text-xl">
            {story.title}
          </p>
          <MDBCardImage
            src={`${story.coverImage}`}
            className="mx-auto mt-2 max-h-48"
            fluid
            alt="..."
          />
          <div className="flex flex-row text-black justify-evenly">
            <p className="text-start che-black-text font-bold capitalize text-lg sm:text-xl">
              {story.author}
            </p>
            <p className="text-end text-blue-600 italic capitalize text-lg sm:text-xl">
              {story.category}
            </p>
          </div>
          <p className="text-lg sm:text-xl px-2">
            {story.body.substring(0, 140)}
            {"..."}
            <b
              onClick={() => {
                navigate("/stories/read-a-story", {
                  state: {
                    id: story.id,
                    name: story.author,
                    body: story.body,
                    img: story.coverImage,
                    category: story.category,
                  },
                });
              }}
              className="text-lg sm:text-xl font-bold che-yellow-text"
            >
              read more.
            </b>
          </p>
        </div>
      );
    });

  const pageCount = Math.ceil(shortstories.length / numberOfPages);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  return (
    <div>
      <div className="container-md mt-3 sm:mt-5">
        <BreadCrumb location="Short Stories" />
        <p className="che-black-text w-3/3 mb-4 sm:w-3/5 mx-auto text-center font-bold text-lg  sm:text-3xl -mt-4 sm:">
          A platform for writers and readers that cover a wide range of poems
          and stories written by skilled hands across Africa.
        </p>

        <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-x-5 gap-y-3">
          {displayShorties}
        </div>
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName="paginationButtons"
          previousLinkClassName="previousButton"
          nextLinkClassName="nextButton"
          activeClassName="paginationActive"
          disabledClassName="paginationDisabled"
          breakLabel={`...`}
        />
      </div>
    </div>
  );
}

export default Stories;
