export const homeAuthors = [
  {
    name: "Ida Maseko",
    types: {
      userType: "Famous Poet",
      title: "Welcome to Malawi",
      description: "Total Poems ",
      total: "41",
    },
    image: "/ida.jpg",
  },
  {
    name: "James Cameron",
    types: {
      userType: "Famous short story Writer",
      title: "Welcome to India",
      description: "Total short stories ",
      total: "30",
    },
    image: "/james.jpg",
  },
  {
    name: "Halsey Doe",
    types: {
      userType: "Famous poet ",
      title: "A story of Mine",
      description: "Total Poems ",
      total: "40",
    },
    image: "/has.jpg",
  },
];

export const items = [
  {
    id: 1,
    author: "Steven Kamwaza",
    title: "A story of mine",
    body: "It's 2009 and I'm 14 and I'm crying Not really sure where I am butI'm holding the hand of my best friend Sam In the waiting room of  a Planned Parenthood The air is sterile and clean, and the walls are that not grey, but green And the lights are so bright they could burn a whole through the seam of my jeans",
    category: "Love",
  },
  {
    id: 2,
    author: "Steven Kamwaza",
    title: "We are one",
    body: "O boy, Ooh Joy Not really sure where I am butI'm holding the hand of my best friend Sam In the waiting room of  a Planned Parenthood The air is sterile and clean, and the walls are that not grey, but green And the lights are so bright they could burn a whole through the seam of my jeans",
    category: "independence",
  },
  {
    id: 3,
    author: "Achebe Chinua",
    title: "Things fall together",
    body: "Alas, litle cry land and I'm crying Not really sure where I am butI'm holding the hand of my best friend Sam In the waiting room of  a Planned Parenthood The air is sterile and clean, and the walls are that not grey, but green And the lights are so bright they could burn a whole through the seam of my jeans",
    category: "Nature",
  },
  {
    id: 4,
    author: "Moya AChinua",
    title: "Things We do together",
    body: " Yes cry land and I'm crying Not really sure where I am butI'm holding the hand of my best friend Sam In the waiting room of  a Planned Parenthood The air is sterile and clean, and the walls are that not grey, but green And the lights are so bright they could burn a whole through the seam of my jeans",
    category: "Love",
  },
  {
    id: 5,
    author: "Achebe Andrwe",
    title: "May be in Tomorrow",
    body: "Alas, litle cry land and I'm crying Not really sure where I am butI'm holding the hand of my best friend Sam In the waiting room of  a Planned Parenthood The air is sterile and clean, and the walls are that not grey, but green And the lights are so bright they could burn a whole through the seam of my jeans",
    category: "Philosophy",
  },
  {
    id: 6,
    author: "Monalisa Banda",
    title: "You Know",
    body: "Not really sure where I am butI'm holding the hand of my best friend Sam In the waiting room of  a Planned Parenthood The air is sterile and clean, and the walls are that not grey, but green And the lights are so bright they could burn a whole through the seam of my jeans",
    category: "Love",
  },
  {
    id: 7,
    author: "Wakisa Nyasulu",
    title: "You Know, My Love",
    body: "I am butI'm holding the hand of my best friend Sam In the waiting room of  a Planned Parenthood The air is sterile and clean, and the walls are that not grey, but green And the lights are so bright they could burn a whole through the seam of my jeans",
    category: "Love",
  },
];

export const cartoons = [
  {
    id: 1,
    author: "This is Africa",
    image:
      "https://i0.wp.com/thisisafrica.me/wp-content/uploads/2022/06/Chinese_racism_video.jpg",
    banner: "This is Africa",
    category: "love",
    authorId: 1,
  },

  {
    id: 2,
    author: "Andulu Kachenje",
    image: "/carto.jpg",
    banner: "Office Boss",
    category: "politics",
    authorId: 2,
  },
  {
    id: 3,
    author: " Tofara Dikani",
    image: "/dobadoba.jpg",
    banner: "Dobadoba",
    category: "love",
    authorId: 1,
  },
  {
    id: 4,
    author: "Ralph Mawera",
    image: "/ayobe.jpg",
    banner: "Ayobe",
    category: "love",
    authorId: 3,
  },
  {
    id: 5,
    author: "Ralph Mawera",
    image: "/pointoforder.jpg",
    banner: "Ayobe",
    category: "love",
    authorId: 3,
  },
];
export const authors = [
  {
    id: 1,
    author: "Hasley",
    body: "It's 2009 and I'm 14 and I'm crying Not really sure where I am butI'm holding the hand of my best friend Sam In the waiting room of  a Planned Parenthood The air is sterile and clean, and the walls are that not grey, but green And the lights are so bright they could burn a whole through the seam of my jeans",
    image: "/has.jpg",
  },
  {
    id: 2,
    author: "Steven Kamwaza",
    body: "O boy, Ooh Joy Not really sure where I am butI'm holding the hand of my best friend Sam In the waiting room of  a Planned Parenthood The air is sterile and clean, and the walls are that not grey, but green And the lights are so bright they could burn a whole through the seam of my jeans",
    image: "/james.jpg",
  },
  {
    id: 3,
    author: "Achebe Chinua",
    body: "Alas, litle cry land and I'm crying Not really sure where I am butI'm holding the hand of my best friend Sam In the waiting room of  a Planned Parenthood The air is sterile and clean, and the walls are that not grey, but green And the lights are so bright they could burn a whole through the seam of my jeans",
    image: "/james.jpg",
  },
  {
    id: 4,
    author: "Moya AChinua",
    body: " Yes cry land and I'm crying Not really sure where I am butI'm holding the hand of my best friend Sam In the waiting room of  a Planned Parenthood The air is sterile and clean, and the walls are that not grey, but green And the lights are so bright they could burn a whole through the seam of my jeans",
    image: "/ida.jpg",
  },
  {
    id: 5,
    author: "Achebe Andrwe",
    body: "Alas, litle cry land and I'm crying Not really sure where I am butI'm holding the hand of my best friend Sam In the waiting room of  a Planned Parenthood The air is sterile and clean, and the walls are that not grey, but green And the lights are so bright they could burn a whole through the seam of my jeans",
    image: "/logo.png",
  },
  {
    id: 6,
    author: "Monalisa Banda",
    body: "I am butI'm holding the hand of my best friend Sam In the waiting room of  a Planned Parenthood The air is sterile and clean, and the walls are that not grey, but green And the lights are so bright they could burn a whole through the seam of my jeans",
    image: "/has.jpg",
  },
  {
    id: 7,
    author: "Wakisa Nyasulu",
    body: "I am butI'm holding the hand of my best friend Sam In the waiting room of  a Planned Parenthood The air is sterile and clean, and the walls are that not grey, but green And the lights are so bright they could burn a whole through the seam of my jeans",
    image: "/ida.jpg",
  },
];

export const cartoonist = [
  {
    id: 1,
    author: "This is Africa",
    body: "It's 2009 and I'm 14 and I'm crying Not really sure where I am butI'm holding the hand of my best friend Sam In the waiting room of  a Planned Parenthood The air is sterile and clean, and the walls are that not grey, but green And the lights are so bright they could burn a whole through the seam of my jeans",
    image: "/has.jpg",
    banner: "This is Africa",
  },
  {
    id: 2,
    author: "Steven Phesery ",
    body: "O boy, Ooh Joy Not really sure where I am butI'm holding the hand of my best friend Sam In the waiting room of  a Planned Parenthood The air is sterile and clean, and the walls are that not grey, but green And the lights are so bright they could burn a whole through the seam of my jeans",
    image: "/Cat.jpg",
    banner: "Oo Boy",
  },
  {
    id: 10,
    author: "Ralph Mawera",
    body: "Alas, litle cry land and I'm crying Not really sure where I am butI'm holding the hand of my best friend Sam In the waiting room of  a Planned Parenthood The air is sterile and clean, and the walls are that not grey, but green And the lights are so bright they could burn a whole through the seam of my jeans",
    image: "/cat3.jpg",
    banner: "Ayobe",
  },
  {
    id: 4,
    author: "Andulu AChinua",
    body: " Yes cry land and I'm crying Not really sure where I am butI'm holding the hand of my best friend Sam In the waiting room of  a Planned Parenthood The air is sterile and clean, and the walls are that not grey, but green And the lights are so bright they could burn a whole through the seam of my jeans",
    image: "/cat1.jpg",
    banner: "no banner",
  },
  {
    id: 5,
    author: "Achebe Andrwe",
    body: "Alas, litle cry land and I'm crying Not really sure where I am butI'm holding the hand of my best friend Sam In the waiting room of  a Planned Parenthood The air is sterile and clean, and the walls are that not grey, but green And the lights are so bright they could burn a whole through the seam of my jeans",
    image: "/cat.jpg",
    banner: "Malume",
  },
  {
    id: 6,
    author: "Moya Nyasulu",
    body: "I am butI'm holding the hand of my best friend Sam In the waiting room of  a Planned Parenthood The air is sterile and clean, and the walls are that not grey, but green And the lights are so bright they could burn a whole through the seam of my jeans",
    image: "/cat3.jpg",
    banner: "ONyasulu",
  },
  {
    id: 7,
    author: "Memory Banda",
    body: "I am butI'm holding the hand of my best friend Sam In the waiting room of  a Planned Parenthood The air is sterile and clean, and the walls are that not grey, but green And the lights are so bright they could burn a whole through the seam of my jeans",
    image: "/ida.jpg",
    banner: "Office",
  },
];

export const shortstories = [
  {
    id: 1,
    author: "Anderw Achebe",
    title: "Quis pariatur ullamco Lorem do.My nation",
    category: "Short",
    body: "Consequat nisi cillum tempor non do anim.Et aute cillum nostrud non qui.Incididunt adipisicing amet nostrud officia deserunt velit enim aute anim. I am butI'm holding the hand of my best friend Sam In the waiting I am butI'm holding the hand of my best friend Sam In the waiting",
    coverImage: "/logo.png",
  },
  {
    id: 2,
    author: "moffat Chinua",
    title: "et consequat cupidatat quis.",
    category: "Short",
    body: "Anim labore dolore nostrud ut amet dolor non nulla.Consequat nisi cillum tempor non do anim.Et aute cillum nostrud non qui.Incididunt adipisicing amet nostrud officia deserunt velit enim aute anim. I am butI'm holding the hand of my best friend Sam In the waiting I am butI'm holding the hand of my best friend Sam In the waiting",
    coverImage: "/logo.png",
  },
  {
    id: 3,
    author: "moffat Chinua",
    title: "Commodo ut proident upidatat quis.",
    category: "Short",
    body: "Anim labore dolore nostrud ut amet dolor non nulla.Consequat nisi cillum tempor non do anim.Et aute cillum nostrud non qui.Incididunt adipisicing amet nostrud officia deserunt velit enim aute anim. I am butI'm holding the hand of my best friend Sam In the waiting I am butI'm holding the hand of my best friend Sam In the waiting",
    coverImage: "/logo.png",
  },
  {
    id: 4,
    author: "janet Phesery",
    title: "proident upidatat quis.",
    category: "Short",
    body: "Minim pariatur officia voluptate in do pariatur sit ullamco consectetur.Anim labore dolore nostrud ut amet dolor non nulla.Consequat nisi cillum tempor non do anim.Et aute cillum nostrud non qui.Incididunt adipisicing amet nostrud officia deserunt velit enim aute anim. I am butI'm holding the hand of my best friend Sam In the waiting I am butI'm holding the hand of my best friend Sam In the waiting",
    coverImage: "/logo.png",
  },
  {
    id: 5,
    author: "Annett Bandawe",
    title: "My Love.",
    category: "Short",
    body: "Sint culpa dolore cillum eu quis anim eu. mollit cillum commodo esse ad magna cillum aute deserunt ad excepteur.Minim pariatur officia voluptate in do pariatur sit ullamco consectetur.Anim labore dolore nostrud ut amet dolor non nulla.Consequat nisi cillum tempor non do anim.Et aute cillum nostrud non qui.Incididunt adipisicing amet nostrud officia deserunt velit enim aute anim. I am butI'm holding the hand of my best friend Sam In the waiting I am butI'm holding the hand of my best friend Sam In the waiting",
    coverImage: "/logo.png",
  },
  {
    id: 6,
    author: "Anne Banda",
    title: "There is,My Love.",
    category: "Short",
    body: "Enim irure in adipisicing reprehenderit irure.Lorem tempor ea ipsum officia. mollit cillum commodo esse ad magna cillum aute deserunt ad excepteur.Minim pariatur officia voluptate in do pariatur sit ullamco consectetur.Anim labore dolore nostrud ut amet dolor non nulla.Consequat nisi cillum tempor non do anim.Et aute cillum nostrud non qui.Incididunt adipisicing amet nostrud officia deserunt velit enim aute anim. I am butI'm holding the hand of my best friend Sam In the waiting I am butI'm holding the hand of my best friend Sam In the waiting",
    coverImage: "/logo.png",
  },
  {
    id: 7,
    author: "Anderw Achebe jr",
    title: "Quis.My nation",
    category: "Short",
    body: "Excepteur sunt ea tempor eu ad amet.Consequat nisi cillum tempor non do anim.Et aute cillum nostrud non qui.Incididunt adipisicing amet nostrud officia deserunt velit enim aute anim. I am butI'm holding the hand of my best friend Sam In the waiting I am butI'm holding the hand of my best friend Sam In the waiting",
    coverImage: "/logo.png",
  },
  {
    id: 8,
    author: "Moddy Chriwa",
    title: "Let's go far, far away.",
    category: "Short",
    body: "Laboris mollit in aliqua dolor sunt.Consectetur in occaecat ullamco culpa ut aliquip reprehenderit exercitation.Anim labore dolore nostrud ut amet dolor non nulla.Consequat nisi cillum tempor non do anim.Et aute cillum nostrud non qui.Incididunt adipisicing amet nostrud officia deserunt velit enim aute anim. I am butI'm holding the hand of my best friend Sam In the waiting I am butI'm holding the hand of my best friend Sam In the waiting",
    coverImage: "/logo.png",
  },
  {
    id: 9,
    author: "Walusungu Kanyimbo",
    title: "The militarynway.",
    category: "Short",
    body: "Dolore sit ad cupidatat sunt tempor reprehenderit tempor cillum deserunt enim consectetur.Anim labore dolore nostrud ut amet dolor non nulla.Consequat nisi cillum tempor non do anim.Et aute cillum nostrud non qui.Incididunt adipisicing amet nostrud officia deserunt velit enim aute anim. I am butI'm holding the hand of my best friend Sam In the waiting I am butI'm holding the hand of my best friend Sam In the waiting",
    coverImage: "/logo.png",
  },
  {
    id: 10,
    author: "Moddy Nyirena",
    title: "The electrons that upidatat quis.",
    category: "nature",
    body: "Elit est quis cillum fugiat est esse amet exercitation quis veniam do culpa.Amet enim voluptate laborum id.Minim pariatur officia voluptate in do pariatur sit ullamco consectetur.Anim labore dolore nostrud ut amet dolor non nulla.Consequat nisi cillum tempor non do anim.Et aute cillum nostrud non qui.Incididunt adipisicing amet nostrud officia deserunt velit enim aute anim. I am butI'm holding the hand of my best friend Sam In the waiting I am butI'm holding the hand of my best friend Sam In the waiting",
    coverImage: "/logo.png",
  },
  {
    id: 11,
    author: "Emannuel Banda",
    title: "The galaxy under the sea.",
    category: "fiction",
    body: "Voluptate sint amet est laborum dolore eu fugiat sit fugiat amet. mollit cillum commodo esse ad magna cillum aute deserunt ad excepteur.Minim pariatur officia voluptate in do pariatur sit ullamco consectetur.Anim labore dolore nostrud ut amet dolor non nulla.Consequat nisi cillum tempor non do anim.Et aute cillum nostrud non qui.Incididunt adipisicing amet nostrud officia deserunt velit enim aute anim. I am butI'm holding the hand of my best friend Sam In the waiting I am butI'm holding the hand of my best friend Sam In the waiting",
    coverImage: "/logo.png",
  },
  {
    id: 12,
    author: "Wakasi Nyasulu",
    title: "Is earth flat?.",
    category: "science",
    body: "Consectetur ut eiusmod aliquip mollit consequat laboris velit ullamco officia voluptate pariatur.Enim irure in adipisicing reprehenderit irure.Non mollit duis voluptate cupidatat eiusmod veniam tempor eu mollit. mollit cillum commodo esse ad magna cillum aute deserunt ad excepteur.Minim pariatur officia voluptate in do pariatur sit ullamco consectetur.Anim labore dolore nostrud ut amet dolor non nulla.Consequat nisi cillum tempor non do anim.Et aute cillum nostrud non qui.Incididunt adipisicing amet nostrud officia deserunt velit enim aute anim. I am butI'm holding the hand of my best friend Sam In the waiting I am butI'm holding the hand of my best friend Sam In the waiting",
    coverImage: "/logo.png",
  },
];
