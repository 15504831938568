import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBBtn,
} from "mdb-react-ui-kit";

export default function HomeFooter() {
  return (
    <MDBFooter className="text-center text-lg-left che-black text-white">
      <MDBContainer className="p-4">
        <MDBRow>
          <MDBCol lg="3" md="6" className="mb-4 mb-md-0 text-start">
            <h5 className="capitalize text-xl sm:text-2xl font-black underline">
              Writers Hands
            </h5>

            <p className="font-bold">A platform for writers and readers.</p>
          </MDBCol>

          <MDBCol lg="3" md="6" className="mb-4 mb-md-0 text-start">
            <h5 className="capitalize text-xl sm:text-2xl font-black underline mb-0">
              Links
            </h5>

            <ul className="list-unstyled text-white text-lg">
              <li>
                <a href="/poems" className="text-white">
                  Poems
                </a>
              </li>
              <li>
                <a href="/poems" className="text-white">
                  Short Stories
                </a>
              </li>
              <li>
                <a href="/poems" className="text-white">
                  Ndakatulo
                </a>
              </li>
              <li>
                <a href="/poems" className="text-white">
                  Nkhani
                </a>
              </li>
            </ul>
          </MDBCol>

          <MDBCol lg="3" md="6" className="mb-4 mb-md-0 text-start">
            <h5 className="capitalize text-xl sm:text-2xl font-black underline mb-0">
              Popular Links
            </h5>
            <ul className="list-unstyled text-lg">
              <li>
                <a href="#!" className="text-white">
                  Login
                </a>
              </li>
              <li>
                <a href="#!" className="text-white">
                  Register
                </a>
              </li>
            </ul>
          </MDBCol>

          <MDBCol lg="3" md="6" className="mb-4 mb-md-0 text-start">
            <h5 className="capitalize text-xl sm:text-2xl font-black underline mb-0">
              Contact Us
            </h5>
            <div className=" text-lg sm:text-xl  mb-0 py-2 sm:space-y-6">
              <p>
                Email:{" "}
                <a
                  href="mailto:writerhand@gmail.com"
                  className="active:hover:text-blue-600 text-white"
                >
                  writerhand@gmail.com
                </a>
              </p>
              <p>Phone: +265 997 239 444</p>
              <div>
                <p>Subscribe to our Newsletter</p>
                <form>
                  <div>
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      autoComplete="email"
                      placeholder="e.g youremail@gmail.com"
                      required
                    />
                    <MDBCol size="auto" className="mb-2 mt-2 mb-md-0">
                      <button
                        type="submit"
                        className="w-full font-semibold bg-[#ff7000] che-yellow py-2 hover:ring active:hover:bg-blue-600 rounded-xl"
                      >
                        Subscribe
                      </button>
                    </MDBCol>
                  </div>
                </form>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <div
        className="text-center py-3 p-3"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
        
      </div>
    </MDBFooter>
  );
}
