import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { items } from "../utils";
const PageSize = 4;
export const RecentPoems = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(items.length / PageSize);
  const startIndex = (currentPage - 1) * PageSize;
  const endIndex = startIndex + PageSize;
  const pageItems = items.slice(startIndex, endIndex);

  return (
    <div className="container-md  mb-4 ">
      <div className="che-yellow-text font-black text-2xl sm:text-4xl flex flex-col sm:flex-row gap-x-8 ">
        <h2 className="">Recent Poems Added</h2>
        <span className=" ml-6 sm:ml-3 gap-6 justify-evenly">
          <button
            className="active:hover:text-gray-900"
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <MDBIcon fas icon="caret-left" />
          </button>
          <button
            className="ml-3 active:hover:text-gray-900"
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <MDBIcon fas icon="caret-right" />
          </button>
        </span>
      </div>

      <div>
        {pageItems.map((item, index) => {
          return (
            <div key={index} className="container-md m-3">
              <h3 className="font-bold text-lg sm:text-xl capitalize text-start">
                {item.title}
              </h3>
              <p className="text-start text-base sm:text-lg uppercase ">
                {item.author}
              </p>
              <MDBBtn
                onClick={() => {
                  navigate("/poems/poem", {
                    state: {
                      id: item.id,
                      title: item.title,
                      body: item.body,
                      author: item.author,
                    },
                  });
                }}
                className="text-dark"
                color="secondary"
              >
                {item.category}
              </MDBBtn>
              <hr className="py-0.5 w-auto" />
            </div>
          );
        })}
      </div>
    </div>
  );
};
