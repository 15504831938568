import { MDBCardImage, MDBIcon } from "mdb-react-ui-kit";
import React, { useState } from "react";
import BreadCrumb from "../components/BreadCrumb";
import { authors } from "../utils";
// Number of authors per page
const PageSize = 6;

function PoetsPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(authors.length / PageSize);
  const startIndex = (currentPage - 1) * PageSize;
  const endIndex = startIndex + PageSize;
  const ourAuthors = authors.slice(startIndex, endIndex);

  return (
    <div>
      <div className="container-md mt-3 sm:mt-5">
        <BreadCrumb location="Poets" />
        <hr className="py-1 sm:py-2 " />
        <h1 className="text-xl mb-2 sm:text-3xl uppercase che-yellow-text font-bold">
          Featured Poets
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-x-3 gap-y-4">
          {ourAuthors.map((item, index) => {
            return (
              <div key={index} className=" img-thumbnail">
                <MDBCardImage
                  src={`${item.image}`}
                  className="mx-auto mt-2 max-h-48"
                  fluid
                  alt="..."
                />
                <h4 className="che-yellow-text font-bold text-start mt-3 ml-3 uppercase text-xl sm:text-2xl">
                  Author
                </h4>
                <p className="ml-3 font-black text-lg sm:text-xl che-black-text ">
                  {item.author}
                </p>
                <p className="pl-3 pr-3  ">
                  {item.body.substring(0, 150)}
                  {"...."}
                  <a className="font-semibold ">Read more</a>
                </p>
              </div>
            );
          })}
        </div>
        <div className="che-yellow-text  text-xl sm:text-3xl py-4  ">
          <h2 className="capitalize opacity-75 font-light text-center">
            View more Authors
          </h2>
          <center className=" mx-auto sm:ml-3 gap-6 justify-evenly">
            <button
              className="active:hover:text-gray-900 border py-2 px-2.5"
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <MDBIcon fas icon="caret-left" />
            </button>
            <button
              className="ml-3 active:hover:text-gray-900 border py-2 px-2.5"
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <MDBIcon fas icon="caret-right" />
            </button>
          </center>
        </div>
      </div>
    </div>
  );
}

export default PoetsPage;
