import HomePage from "./pages/HomePage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Login from "./components/Login";
import Register from "./components/Register";
import { Poem } from "./pages/Poem";
import PoetsPage from "./pages/PoetsPage";
import PoemsAuthor from "./components/PoemsAuthor";
import Stories from "./pages/Stories";
import Cartoons from "./pages/Cartoons";
import CartoonAuthor from "./components/CartoonAuthor";
import CartoonAuthors from "./pages/CartoonAuthors";
import Page404 from "./pages/Page404";
import AppHome from "./pages/writers/AppHome";
import { AuthProvider } from "./utils/auth";
import ProtectRoute from "./components/ProtectRoute";
import PoemPage from "./components/PoemPage";
import ShortStory from "./components/ShortStory";
import StoriesWriters from "./pages/StoriesWriters";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/*"
            element={
              <>
                <Layout>
                  <Routes>
                    <Route exact path="/" element={<HomePage />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route exact path="*" element={<Page404 />} />
                  </Routes>
                </Layout>
              </>
            }
          />
          <Route
            path="/home/*"
            element={
              <>
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={
                      <ProtectRoute>
                        <AppHome />
                      </ProtectRoute>
                    }
                  />
                  <Route exact path="*" element={<Page404 />} />
                </Routes>
              </>
            }
          />
          <Route
            path="/poems/*"
            element={
              <Layout>
                <Routes>
                  <Route exact path="/" element={<Poem />} />
                  <Route path="/authors" element={<PoetsPage />} />
                  <Route path="/author" element={<PoemsAuthor />} />
                  <Route path ="/poem" element ={<PoemPage/>}/>
                                  </Routes>
              </Layout>
            }
          />
          <Route
            path="/stories/*"
            element={
              <Routes>
                <Route
                  exact
                  path="/"
                  element={
                    <Layout>
                      <Stories />
                    </Layout>
                  }
                />
                <Route path="/read-a-story" element={<Layout><ShortStory/></Layout>}/>
                <Route path="/authors" element={<Layout><StoriesWriters /></Layout>}/>
                <Route path="*" element={<Page404 />} />
              </Routes>
            }
          />
          <Route
            path="/cartoons/*"
            element={
              
                <Routes>
                  <Route exact path="/" element={<Layout><Cartoons /></Layout>} />
                  <Route path="/authors" element={<Layout><CartoonAuthors /></Layout>} />
                  <Route path="/author" element={<Layout><CartoonAuthor /></Layout>} />
                  <Route exact path="*" element={<Page404 />} />
                </Routes>
              
            }
          />
          <Route exact path="*" element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
