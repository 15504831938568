import React, { useState } from "react";
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
  MDBBtn,
  MDBIcon,
  MDBNavbarNav,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdb-react-ui-kit";
import { useAuth } from "../utils/auth";
import { Link } from "react-router-dom";

export default function Header() {
  const [showNavNoToggler, setShowNavNoToggler] = useState(false);
  const auth = useAuth();
  return (
    <div className="">
      <MDBNavbar
        expand="md"
        fixed
        className="bg-gray-200 border-b-2 border-gray-300"
      >
        <MDBContainer
          fluid
          className="mt-3 sm:mt-3 items-center sm:container-md"
        >
          <MDBNavbarToggler
            type="button"
            className="bg-white "
            data-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowNavNoToggler(!showNavNoToggler)}
          >
            <MDBIcon icon="bars" fas className=" text-3xl" />
          </MDBNavbarToggler>

          <MDBCollapse navbar show={showNavNoToggler}>
            <br />
            <MDBNavbarNav className="mx-auto mr-auto sm:mr-3 mb-1 sm:text-lg mb-lg-0">
              <MDBNavbarItem>
                <MDBNavbarLink
                  aria-current="page"
                  className="text-dark font-bold text-xl sm:text-3xl"
                  href="/"
                >
                  Front Cover
                </MDBNavbarLink>
              </MDBNavbarItem>

              <MDBNavbarItem>
                <MDBDropdown>
                  <MDBDropdownToggle
                    tag="a"
                    className="nav-link  text-dark ml-4 sm:ml-3 sm:justify-evenly"
                    role="button"
                  >
                    Poems
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem link className="text-dark" href="/poems">
                      Poems
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      link
                      className="text-dark"
                      href="/poems/authors"
                    >
                      Poets
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>

              <MDBNavbarItem>
                <MDBDropdown>
                  <MDBDropdownToggle
                    tag="a"
                    className="nav-link text-dark ml-4"
                    role="button"
                  >
                    Short Stories
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem link className="text-dark" href="/stories">
                      Short stories
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      link
                      className="text-dark"
                      href="/stories/authors"
                    >
                      Poets
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBDropdown>
                  <MDBDropdownToggle
                    tag="a"
                    className="nav-link text-dark ml-4"
                    role="button"
                  >
                    Cartoons
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem
                      link
                      className="text-dark"
                      href="/cartoons"
                    >
                      Cartoons
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      link
                      className="text-dark"
                      href="/cartoons/authors"
                    >
                      Cartoon Designers
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBDropdown>
                  <MDBDropdownToggle
                    tag="a"
                    className="nav-link text-dark ml-4"
                    role="button"
                  >
                    Artworks
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem link className="text-dark" href="/">
                      Painting & Drawings
                    </MDBDropdownItem>
                    <MDBDropdownItem link className="text-dark" href="/">
                      Artists
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBDropdown>
                  <MDBDropdownToggle
                    tag="a"
                    className="nav-link text-dark ml-4"
                    role="button"
                  >
                    Switch Languages
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem link className="text-dark" href="/">
                      English
                    </MDBDropdownItem>
                    <MDBDropdownItem link className="text-dark" href="/">
                      Chichewa
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>
            </MDBNavbarNav>
          </MDBCollapse>
          <MDBNavbarBrand className="sm:gap-x-6">
            {!auth.user && (
              <Link to="/login" className="che-black-text ">
                Login
              </Link>
            )}
            <img
              src="/logo.png"
              className="object-contain h-16 sm:h-24"
              fluid
            />
          </MDBNavbarBrand>
        </MDBContainer>
      </MDBNavbar>
    </div>
  );
}
