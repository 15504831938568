import React from "react";
import { RecentPoems } from "../components/RecentPoems";
import BreadCrumb from "../components/BreadCrumb";

export const Poem = () => {
  return (
    <div>
      <div className="container-md mt-3 sm:mt-5">
        <BreadCrumb location="Poems"/>
        <div>
          <h3 className="font-black che-black-text mb-4 text-2xl sm:text-5xl text-center">
            Topics
          </h3>
{/* needs to be edited */}
          <div className="container-md gap-3 mx-auto mb-4 grid grid-cols-1 justify-between sm:grid-cols-1 md:lg:grid-cols-3">
            <div className="border text-center rounded-3xl">
              <h2 className=" mt-3 che-yellow-text font-normal">Subjects</h2>
              <div className="flex flex-col mb-2 items-center grow">
                <a className="text-xl sm:text-2xl font-normal underline decoration-slice che-black-text">
                  Love
                </a>
                <a className="text-xl sm:text-2xl font-normal underline decoration-slice che-black-text">
                  Living
                </a>
                <a className="text-xl sm:text-2xl font-normal underline decoration-slice che-black-text">
                  Nature
                </a>
                <a className="text-xl sm:text-2xl font-normal underline decoration-slice che-black-text">
                  Art and Science
                </a>
                <a className="text-xl sm:text-2xl font-normal underline decoration-slice che-black-text">
                  Religion
                </a>
              </div>
              <button className=" py-2 px-3 rounded-3xl text-lg sm:text-xl font-bold active:hover:bg-blue-600 active:hover:ring focus:ring mb-3 che-yellow text-white">
                View more
              </button>
            </div>

            <div className="border text-center rounded-3xl">
              <h2 className=" mt-3 che-yellow-text font-normal">Occasions</h2>
              <div className="flex flex-col mb-2 items-center grow">
                <a className="text-xl sm:text-2xl font-normal underline decoration-slice che-black-text">
                  Anniversary
                </a>
                <a className="text-xl sm:text-2xl font-normal underline decoration-slice che-black-text">
                  Birth
                </a>
                <a className="text-xl sm:text-2xl font-normal underline decoration-slice che-black-text">
                  Birthdays
                </a>
                <a className="text-xl sm:text-2xl font-normal underline decoration-slice che-black-text">
                  Engagement & Weddings
                </a>
                <a className="text-xl sm:text-2xl font-normal underline decoration-slice che-black-text">
                  Graducation
                </a>
              </div>
              <button className=" py-2 px-3 rounded-3xl text-lg sm:text-xl font-bold active:hover:bg-blue-600 active:hover:ring focus:ring mb-3 che-yellow text-white">
                View more
              </button>
            </div>

            <div className="border text-center rounded-3xl">
              <h2 className=" mt-3 che-yellow-text font-normal">Holiday</h2>
              <div className="flex flex-col mb-2 items-center grow">
                <a className="text-xl sm:text-2xl font-normal underline decoration-slice che-black-text">
                  Christmas
                </a>
                <a className="text-xl sm:text-2xl font-normal underline decoration-slice che-black-text">
                  Easter
                </a>
                <a className="text-xl sm:text-2xl font-normal underline decoration-slice che-black-text">
                  Father's Day
                </a>
                <a className="text-xl sm:text-2xl font-normal underline decoration-slice che-black-text">
                  Mothers Day
                </a>
                <a className="text-xl sm:text-2xl font-normal underline decoration-slice che-black-text">
                  Independence Day
                </a>
              </div>
              <button className=" py-2 px-3 rounded-3xl text-lg sm:text-xl font-bold active:hover:bg-blue-600 active:hover:ring focus:ring mb-3 che-yellow text-white">
                View more
              </button>
            </div>
          </div>
        </div>
      </div>
      <RecentPoems/>
    </div>
  );
};
