import { MDBIcon } from "mdb-react-ui-kit";
import React, { useRef } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { items } from "../utils";

function PoemPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const gotoTop = useRef(null);

  function handleClick() {
    gotoTop.current.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  if (!location.state || !location.state.id) {
    return <Navigate to="/poems" />;
  }
  return (
    <div ref={gotoTop}>
      <div className="ml-4 container-md py-3 sm:py-6">
        <p
          onClick={() => navigate("/poems", { state: null })}
          className=" sm:ml-24 mt-4 font-black text-base sm:text-lg text-start opacity-70 active:hover:text-blue-600"
        >
          <MDBIcon fas icon="angle-left" /> Back to Previous
        </p>
        <div className="mx-auto w-2/3 sm:w-1/3">
          <h1 className="text-2xl sm:text-3xl ml-4 font-black che-yellow-text">
            {location.state.title}
          </h1>
          <p className="text-lg sm:text-xl italic ml-4">
            {location.state.author}
          </p>
          <p className="text-lg sm:text-xl ml-4">{location.state.body}</p>
        </div>
        <div>
          <p className="ml-4 sm:ml-24 uppercase border-b-4 border-gray-400 w-max-w container-wrap text-start p-3 che-black text-white py-2.5 h-auto px-2.5 font-bold text-xl ">
            Other poems by {location.state.author}
          </p>
          <center className="mx-auto grid grid-cols-1 sm:grid-cols-2 md:lg:grid-cols-3 gap-x-4">
            {items.map((item, index) => {
              if (location.state.author == item.author) {
                return (
                  <p
                    key={index}
                    onClick={() => {
                      navigate("/poems/poem", {
                        state: {
                          id: item.id,
                          title: item.title,
                          body: item.body,
                          author: item.author,
                        },
                      });
                      handleClick();
                    }}
                    className="mb-3 mx-auto py-2 text-center border-2 capitalize  px-3 text-center underline hover:active:text-blue-600 text-lg sm:text-xl"
                  >
                    {item.title}
                  </p>
                );
              }
            })}
          </center>
        </div>
      </div>
    </div>
  );
}

export default PoemPage;
