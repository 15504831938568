import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const Register = () => {
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Enter your name"),
    phone: Yup.string()
      .required("Enter your phone number"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Enter your email"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Enter your password"),
    cpassword: Yup.string()
      .required('Please retype your password.')
      .oneOf([Yup.ref('password')], 'Your passwords do not match')
  });

  return (
    <Formik
      initialValues={{
        name:"",
        phone:"",
        email: "",
        password: "",

      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        
        alert(values.password);
      }}
    >
      {({ isSubmitting }) => (
        <div className="bg-white container mt-3 sm:mt-5 py-4 sm:py-10 items-center justify-center">
          <div className="container-md mx-auto">
            <h3 className="text-center font-black text-2xl sm:text-5xl leading-4 sm:leading-7">
              Writers Hand
            </h3>
            <p className="text-center mx-auto sm:w-2/3 text-lg sm:text-2xl ">
              Are you a writer?
            </p>
            <h3 className="text-center font-semibold text-2xl sm:text-4xl">
              Register Now{" "}
            </h3>
            <Form className="py-5 sm:py-8 mx-auto sm:w-3/5 items-center border rounded-md p-4 sm:p-10 ">
              <div class="overflow-hidden relative mx-auto w-14 h-14 bg-gray-100 rounded-full">
                <svg
                  class="absolute -left-1 w-16 h-16 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <div className="mb-2 mt-3">
                <label
                  for="email-address-icon"
                  class="block mb-2 text-base font-medium text-gray-900"
                >
                  Full Name
                </label>
                <div className="relative">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500"
                      fill="currentColor"
                      viewBox="0 0 448 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                    </svg>
                  </div>
                  <Field
                    type="text"
                    name="name"
                    placeholder="e.g Mwale Mweso"
                    className=" border text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                  />
                </div>
                <div className="text-red-600">
                  <ErrorMessage name="name" component="div" />
                </div>
              </div>
              <div className="mb-2 mt-3">
                <label
                  for="email-address-icon"
                  class="block mb-2 text-base font-medium text-gray-900"
                >
                  Phone Number
                </label>
                <div className="relative">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500"
                      fill="currentColor"
                      viewBox="0 0 448 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M80 0C44.7 0 16 28.7 16 64V448c0 35.3 28.7 64 64 64H304c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H80zm80 432h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H160c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                    </svg>
                  </div>
                  <Field
                    type="text"
                    name="phone"
                    placeholder="e.g +265********"
                    className=" border text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                  />
                </div>
                <div className="text-red-600">
                  <ErrorMessage name="phone" component="div" />
                </div>
              </div>
              <div className="mb-2">
                <label
                  for="email-address-icon"
                  class="block mb-2 text-base font-medium text-gray-900"
                >
                  Email
                </label>
                <div className="relative">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500"
                      fill="currentColor"
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                    </svg>
                  </div>
                  <Field
                    type="email"
                    name="email"
                    placeholder="Email"
                    className=" border text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                  />
                </div>
                <div className="text-red-600">
                  <ErrorMessage name="email" component="div" />
                </div>
              </div>

              <div className="mb-2">
                <label
                  for="email-address-icon"
                  class="block mb-2 text-base font-medium text-gray-900"
                >
                  Password
                </label>
                <div className="relative">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500"
                      fill="currentColor"
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M336 352c97.2 0 176-78.8 176-176S433.2 0 336 0S160 78.8 160 176c0 18.7 2.9 36.8 8.3 53.7L7 391c-4.5 4.5-7 10.6-7 17v80c0 13.3 10.7 24 24 24h80c13.3 0 24-10.7 24-24V448h40c13.3 0 24-10.7 24-24V384h40c6.4 0 12.5-2.5 17-7l33.3-33.3c16.9 5.4 35 8.3 53.7 8.3zm40-176c-22.1 0-40-17.9-40-40s17.9-40 40-40s40 17.9 40 40s-17.9 40-40 40z" />
                    </svg>
                  </div>
                  <Field
                    type="password"
                    name="password"
                    placeholder="Password"
                    className=" border text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                  />
                </div>
                <div className="text-red-600">
                  {" "}
                  <ErrorMessage name="password" component="div" />
                </div>
              </div>
              <div className="mb-2">
                <label
                  for="email-address-icon"
                  class="block mb-2 text-base font-medium text-gray-900"
                >
                  Confirm Password
                </label>
                <div className="relative">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500"
                      fill="currentColor"
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M336 352c97.2 0 176-78.8 176-176S433.2 0 336 0S160 78.8 160 176c0 18.7 2.9 36.8 8.3 53.7L7 391c-4.5 4.5-7 10.6-7 17v80c0 13.3 10.7 24 24 24h80c13.3 0 24-10.7 24-24V448h40c13.3 0 24-10.7 24-24V384h40c6.4 0 12.5-2.5 17-7l33.3-33.3c16.9 5.4 35 8.3 53.7 8.3zm40-176c-22.1 0-40-17.9-40-40s17.9-40 40-40s40 17.9 40 40s-17.9 40-40 40z" />
                    </svg>
                  </div>
                  <Field
                    type="password"
                    name="cpassword"
                    placeholder="Confirm Password"
                    className=" border text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                  />
                </div>
                <div className="text-red-600">
                  {" "}
                  <ErrorMessage name="cpassword" component="div" />
                </div>
              </div>

              <button
                type="submit"
                className="che-black w-full uppercase  py-2.5 rounded-md cursor-pointer  text-white text-bold active:hover:bg-blue-600 focus:ring  "
                disabled={isSubmitting}
              >
                Register
              </button>
              <div>
                <a
                  href="/login"
                  className="text-base text-gray-500 text-end text-right mt-2"
                >
                  Already have an account?{" "}
                  <b className="text-blue-600 underline">Login Now</b>
                </a>
              </div>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
};
export default Register;
