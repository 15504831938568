import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../utils/auth'

const AppHome = () => {
  const auth = useAuth();
  const navigate =useNavigate();

console.log(auth.user)
  const handleLogout = () => {
    auth.logout();
    navigate('/');
  }
  return (
    <div>
      <h1>Hello, {auth.user}!</h1>
<button onClick={handleLogout}>Logout Now</button>
    </div>
  )
}

export default AppHome