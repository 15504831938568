import React, { useState } from "react";
import { MDBIcon } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import { replace } from "formik";
const PageSize = 1;

const PoemsHomeSlider = ({ items }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1); 
  const totalPages = Math.ceil(items.length / PageSize); 
  const startIndex = (currentPage - 1) * PageSize; 
  const endIndex = startIndex + PageSize; 
  const poems = items.slice(startIndex, endIndex); 

  return (
    <div className="select-none">
      <div>
        <div className="container justify-center items-center flex gap-3 text-white">
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <MDBIcon
              fas
              icon="angle-left"
              className="font-black text-3xl sm:text-5xl hover:animate-bounce  hover:text-gray-400"
            />
          </button>
          <h1>Poems</h1>
          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <MDBIcon
              fas
              icon="angle-right"
              className="font-black text-3xl sm:text-5xl hover:animate-bounce hover:text-gray-400"
            />
          </button>
        </div>
        {poems.map((item, index) => {
          return (
            <div
              key={index}
              className="container pl-4 pr-4 mt-4 sm:w-2/5  mb-4 bg-white rounded-2xl py-2 sm:py-4"
            >
              <div className="flex text-center py-2 justify-center gap-3 sm:gap-8">
                <h3 className="che-yellow-text ">Poem of the Day</h3>
                <span className="text-lg sm:text-lg">
                  <MDBIcon
                    fas
                    icon="play-circle"
                    className="che-yellow-text text-2xl sm:text-3xl"
                  />{" "}
                  Listen
                </span>
              </div>
              <div>
                <h4 className="text-center text-xl sm:text-3xl font-bold capitalize">
                  {item.title}
                </h4>
                <p className="text-center ml-9 sm:ml-18 text-lg sm:text-xl italic">
                  By: {item.author}
                </p>
                <p className="w-4/5 sm:w-2/5 mx-auto text-lg whitespace-pre-line sm:text-xl ">
                  {item.body}
                  <br />
                  <a
                    onClick ={()=>{
                      navigate("/poems/poem",{state : {id: item.id, title:item.title,body:item.body ,author:item.author}})
                    }}
                    className="text-end text-gray-900 font-bold underline cursor-pointer hover:active:text-blue-600"
                  >
                    Readmore
                  </a>
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PoemsHomeSlider;
